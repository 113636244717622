// eslint-disable-next-line
// const echarts = equire([
//     'bar',
//     'line',
//     'pie',
//     'legend',
//     'title',
//     'tooltip',
//     'toolbox',
//     'grid'
// ])
// import * as echarts from 'echarts/core';
// import {
//     TitleComponent,
//     ToolboxComponent,
//     TooltipComponent,
//     GridComponent,
//     LegendComponent,
//     DataZoomComponent
// } from 'echarts/components';
// import {
//     BarChart,
//     LineChart,
//     PieChart
// } from 'echarts/charts';
// import {
//     CanvasRenderer
// } from 'echarts/renderers';

// echarts.use(
//     [TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, LineChart, CanvasRenderer, DataZoomComponent, PieChart]
// );
import  * as echarts from 'echarts'
export default echarts