<template>
  <el-tooltip :disabled="disabled" placement="top-start" :content="text" effect="light">
    <div slot="content" v-if="$slots.content">
        <slot name="content"></slot>
    </div>
    <span v-if="type === 'icon'" :class="icon"></span>
    <span v-if="type === 'text'">{{content}}</span>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      default: "el-icon-question",
    },
    text: {
      default: "",
    },
    type: {
      default: 'icon'
    },
    content: {
      default: ''
    },
    disabled: {
      default: false
    }
  },
};
</script>
