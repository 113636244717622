<template>
  <div>
    <el-select
      size="mini"
      class="m-t-10 m-b-20"
      style="width: 100px"
      v-model="echartType"
      v-if="showEchartSelect"
    >
      <el-option
        v-for="item in echartTypes"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <div
      class="data-echart flex-row flex-wrap"
      :style="{
        height: chartHeight
      }"
    >
      <i
        class="el-icon-refresh"
        @click="refreshEchart"
        v-if="echartType !== 'pie'"
      ></i>
      <div
        v-if="echartType === 'pie' && pieData1.length"
        :id="echartIds.myEchart2"
        :style="{
          width: pieData2.length ? '50%' : '100%',
          height: pieData3.length ? '50%' : '100%',
        }"
      ></div>
      <div
        v-if="echartType === 'pie' && pieData2.length"
        :id="echartIds.myEchart3"
        :style="{ width: '50%', height: pieData3.length ? '50%' : '100%' }"
      ></div>
      <div
        v-if="echartType === 'pie' && pieData3.length"
        :id="echartIds.myEchart4"
        style="width: 50%; height: 50%"
      ></div>
      <div
        v-if="echartType === 'pie' && pieData4.length"
        :id="echartIds.myEchart5"
        style="width: 50%; height: 50%"
      ></div>
      <div
        v-show="echartType !== 'pie'"
        :id="echartIds.myEchart1"
        style="width: 100%; height: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
import echarts from "@/tools/initEcharts.js";
export default {
  components: {},
  props: {
    data: {
      default: () => {},
    },
    dataType: {
      type: String,
      default: "main",
    },
    nameList: {
      type: Array,
      default: () => [],
    },
    lineList: {
      type: Array,
      default: () => [],
    },
    pieData1: {
      default: () => [],
    },
    pieData2: {
      default: () => [],
    },
    pieData3: {
      default: () => [],
    },
    pieData4: {
      default: () => [],
    },
    pieDataName1: String,
    pieDataName2: String,
    pieDataName3: String,
    pieDataName4: String,
    customizedUnit: String,
    noPie: {
      default: false,
    },
  },
  data() {
    return {
      echartType: "line",
      dataZoom: {
        start: 0,
        end: 100,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
    echartTypes() {
      const echartTypes = [
        {
          value: "line",
          label: "折线图",
        },
        {
          value: "bar",
          label: "柱状图",
        },
        {
          value: "pie",
          label: "环形图",
        },
        {
          value: "all",
          label: "混合图",
        },
      ];
      return echartTypes;
    },
    echartIds() {
      const type = this.dataType;
      return {
        myEchart1: "myEchart1" + type,
        myEchart2: "myEchart2" + type,
        myEchart3: "myEchart3" + type,
        myEchart4: "myEchart4" + type,
        myEchart5: "myEchart5" + type,
      };
    },
    showEchartSelect() {
      const type = this.dataType;
      return (
        type !== "businessCount" && type !== "gameRank" && type !== "saleRank"
      );
    },
  chartHeight() {
    if (!this.showEchartSelect) return '800px'
    return this.echartType === 'pie' && this.pieData3.length ? '880px' : '550px'
  }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.dataType === 'gameRocord') {
      this.echartType = 'all'
    }
    !this.showEchartSelect && (this.dataZoom.start = 0);
  },
  //监控data中的数据变化
  watch: {
    echartType(val) {
      if (val !== "pie") {
        this.$emit("pieType", false);
        this.$nextTick(() => {
          this.echartsInit(this.data);
        });
      } else {
        this.$emit("pieType", true);
        this.$nextTick(() => {
          !this.noPie && this.echartsInitPieAll();
        });
      }
    },
    data: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          this.echartsInit(val);
        });
      },
    },
    pieData1: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          val.length &&
            this.echartsInitPie(this.echartIds, 1, this.pieDataName1);
        });
      },
    },
    pieData2: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          val.length &&
            this.echartsInitPie(this.echartIds, 2, this.pieDataName2);
        });
      },
    },
    pieData3: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          val.length &&
            this.echartsInitPie(this.echartIds, 3, this.pieDataName3);
        });
      },
    },
    pieData4: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          val.length &&
            this.echartsInitPie(this.echartIds, 4, this.pieDataName4);
        });
      },
    },
  },

  methods: {
    echartsInit(data) {
      // let a = 1
      //   if (!Array.isArray(data) || data.length === 0) return;
      let echartIds = this.echartIds,
        myChart1 = echarts.init(document.getElementById(echartIds.myEchart1)),
        echartType = this.echartType,
        isAll = echartType === "all",
        showEchartSelect = this.showEchartSelect,
        nameList = this.nameList,
        { dateArr, dataArr1, dataArr2 } = this.dealParams(data);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        toolbox: {
          show: true,
          feature: {
            // restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        calculable: true,
        legend:
            {
                // data: isAll || !showEchartSelect ? lineList : nameList,
                data: nameList,
                itemGap: 5,
                left: 100,
                selected: {},
              },
        grid: {
          top: "12%",
          left: "1%",
          right: "10%",
          containLabel: true,
        },
        xAxis: [
          // 判断是否条形图，showEchartSelect为false代表显示条形图
          showEchartSelect
            ? {
                type: "category",
                data: dateArr,
              }
            : {
                type: "value",
                name: "",
              },
        ],
        yAxis: [
          showEchartSelect
            ? {
                type: "value",
                name: "",
              }
            : {
                type: "category",
                data: dateArr,
              },
        ],
        dataZoom: [
          {
            show: showEchartSelect,
            ...this.dataZoom,
          },
          {
            type: "inside",
            start: 94,
            end: 100,
          },
          {
            show: true,
            yAxisIndex: 0,
            filterMode: "empty",
            width: 30,
            height: "80%",
            showDataShadow: false,
            left: "93%",
          },
        ],
        series: isAll ? [...dataArr1, ...dataArr2] : [...dataArr1],
      };
      myChart1.clear();
      myChart1.setOption(option);
      isAll &&
        myChart1.on("legendselectchanged", (e) => {
          option.legend.selected[e.name] = true;
          option.series.forEach((item) => {
            if (item.name === e.name) {
              item.type = item.type === "line" ? "bar" : "line";
            }
          });
          option.dataZoom[0] = {
            show: showEchartSelect,
            ...this.dataZoom,
          };
          myChart1.setOption(option);
        });
      myChart1.on("datazoom", (e) => {
        const params = e.batch ? e.batch[0] : e;
        let { start, end } = params;
        this.dataZoom = { start, end };
      });
    },
    echartsInitPieAll() {
      let echartIds = this.echartIds,
        myChart1 = echarts.init(document.getElementById(echartIds.myEchart1));
      myChart1.clear();
      this.pieData1.length &&
        this.echartsInitPie(echartIds, 1, this.pieDataName1);
      this.pieData2.length &&
        this.echartsInitPie(echartIds, 2, this.pieDataName2);
      this.pieData3.length &&
        this.echartsInitPie(echartIds, 3, this.pieDataName3);
      this.pieData4.length &&
        this.echartsInitPie(echartIds, 4, this.pieDataName4);
    },
    echartsInitPie(echartIds, num, name) {
      let myChart,
        pieData = this[`pieData${num}`];
      this.$nextTick(() => {
        myChart = echarts.init(
          document.getElementById(echartIds[`myEchart${num + 1}`])
        );
        myChart.clear();
        let index = 0;
        let option = {
          title: {
            show: true,
            text: name,
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              let sum = pieData[index].total
                ? `<br/>总数:${pieData[index].total}${this.customizedUnit}`
                : "";
              // // return params.seriesName + "<br/>" + params.name + ":" + params.value + sum;
              return (
                params.name +
                ":" +
                params.value +
                sum +
                `<br/>百分比:${params.percent}%`
              );
              // // return JSON.stringify(params)
              // return getFormatter(params);
            },
          },
          legend: {
            top: "bottom",
          },
          series: [
            {
              name,
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "40",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: pieData,
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    dealParams(data) {
      if (this.showEchartSelect) {
        // 是否是条形图的数据处理
        let nameList = this.nameList,
          lineList = this.lineList,
          echartType = this.echartType,
          isAll = echartType === "all",
          dateArr = [data[0].time],
          dataArr1 = data[0].chartDetailVOList.map(({ key, value }) => ({
            name: nameList[+key - 1],
            type: isAll ? "bar" : echartType,
            data: [value],
          })),
          dataArr2 = [];
        if (isAll) {
          data[0].chartDetailVOList.forEach(({ key, value }) => {
            lineList.includes(nameList[key - 1]) &&
              dataArr2.push({
                name: nameList[+key - 1],
                type: "line",
                data: [value],
              });
          });
        }
        for (let i = 1; i < data.length; i++) {
          const list = data[i].chartDetailVOList;
          dateArr.push(data[i].time);
          dataArr1.forEach((item, index) => {
            item.data.push(list[index].value);
          });
          if (isAll) {
            list.forEach((eachItem) => {
              const findName = dataArr2.find(
                (findItem) => findItem.name === nameList[+eachItem.key - 1]
              );
              if (findName) {
                findName.data.push(eachItem.value);
              }
            });
          }
        }
        if (isAll) {
          dataArr1 = dataArr1.filter((item) => !lineList.includes(item.name));
        }
        return {
          dateArr,
          dataArr1,
          dataArr2,
        };
      } else {
        return data;
      }
    },
    refreshEchart() {
      this.dataZoom = {
        start: 0,
        end: 100,
      };
      this.echartsInit(this.data);
    },
    // getFormatter(params) {

    // },
  },
  destroyed() {},
};
</script>
    
<style lang='scss' scoped>
.data-echart {
  width: 792px;
  background: #eeeeee;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  display: flex;
  position: relative;
}
.el-icon-refresh {
  position: absolute;
  right: 30px;
  top: 8px;
  cursor: pointer !important;
  transform: scale(1.2);
  z-index: 1000;
}
</style>