<!--  -->
<template>
  <div>
    <el-dialog
      title="剔除名单"
      :visible.sync="dialogVisible"
      class="ignore"
      width="768px"
    >
      <div  class="title1">剔除规则</div>
      <div   class="mb-2">
        <div class="flex-ac mb-1">
          <span> 1.剔除群体用户：个人累计游戏时长低于 </span>
          <el-input
            style="width: 100px; margin: 0 10px"
            size="mini"
            type="number"
            max="60"
            min="0"
            @input="changeIgnoreMinute"
            v-model="ignoreMinute"
          ></el-input>
          <span>分钟的多个用户；</span>
        </div>
        <div>2.剔除个别用户：以下剔除名单中的用户。</div>
      </div>
      <div class="title1">剔除个别用户3</div>
      <div style="display: flex; padding: 0 30px">
        <div class="left-wrap">
          <div class="bg-purple title" style="">搜索用户</div>
          <div style="display: flex">
            <el-input
              size="mini"
              placeholder="请输入内容"
              v-model="inputValue"
              @keydown.enter.native="searchUserList()"
            >
            </el-input>
            <el-button size="mini" @click="searchUserList()" type="primary"
              >搜索</el-button
            >
          </div>
          <div v-loading="userListLoading" class="left-list">
            <div
              v-for="(item, index) in userList"
              :key="index"
              class="flex space-between mt-3"
            >
              <div>{{ item.name }}</div>
              <div class="add-btn" @click="addUserIgnore(item.id)"></div>
            </div>
          </div>
          <div class="flex-cc">
            <el-pagination
              :total="userPagination.count"
              :current-page="userPagination.curPage"
              :page-size="userPagination.size"
              :pager-count="5"
              @current-change="searchUserList"
              layout="prev, pager, next"
            ></el-pagination>
          </div>
        </div>
        <div style="width: 60%">
          <!-- 表头 -->
          <div class="flex space-between text-c">
            <div style="min-width: 18px"></div>
            <div style="width: 30%">用户名称</div>
            <div style="width: 54px">平台</div>
            <div style="width: 50px">操作</div>
          </div>
          <!-- 行数据 -->
          <div v-loading="ignoreListLoading" style="height: 361px">
            <div
              class="item"
              v-for="(item, i) in ignoreList"
              :key="i"
              :style="{
                background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
              }"
            >
              <!-- 商品序号 -->
              <div class="item-index">
                {{ i + 1 }}
              </div>
              <div class="shenglue" style="width: 30%">
                {{ item.nickname }}
              </div>

              <div class="other" :style="{ background: platform[1].color }">
                {{ item.platformName }}
              </div>
              <div class="del-btn" @click="delUserIgnore(item.id)">删除</div>
            </div>
          </div>
          <div class="flex-cc">
            <el-pagination
              :total="ignorePaginationCount"
              :current-page="ignorePagination.currPage"
              :page-size="ignorePagination.limit"
              :pager-count="5"
              @current-change="getUserIgnore"
              layout="prev, pager, next"
            ></el-pagination>
          </div>
        </div>
      </div>

      <div slot="title" class="slot-title text-c">
        <span class="ignore-title" style=""> 剔除名单</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const platform = {
  0: {
    color: "#D7D5DF",
    text: "其他",
  },
  1: {
    color: "#8BD1CB",
    text: "微信",
  },
  2: {
    color: "#F8CDE3",
    text: "Bilibili",
  },
  3: {
    color: "#666666",
    text: "抖音",
  },
  4: {
    color: "#90B6E6",
    text: "APP",
  },
};
export default {
  data() {
    this.platform = platform;
    return {
      type: 1,
      dialogVisible: false,
      userPagination: {
        count: 10,
        curPage: 1,
        limit: 10,
        name: "",
      },
      ignorePagination: {
        currPage: 1,
        limit: 12,
      },
      ignoreList: [],
      inputValue: "",
      userList: [],
      userListLoading: false,
      ignoreListLoading: false,
      ignorePaginationCount: 0,
      ignoreMinute: "",
    };
  },
  computed: {},
  mounted() {
    this.getUserIgnore();
    this.ignoreMinute = localStorage.ignoreMinute || 5;
  },
  watch: {
    dialogVisible(v) {
      !v && this.$emit("change");
    },
  },
  methods: {
    changeIgnoreMinute(v) {
      if (v && (+v < 0 || +v > 60)) {
        return this.$message.warning(+v < 0 ? "必须大于0" : '必须小于等于60');
      }
      localStorage.ignoreMinute = v;
    },
    openDialog() {
      this.dialogVisible = true;
      this.$forceUpdate();
    },
    async getUserIgnore(page) {
      page && (this.ignorePagination.currPage = page);
      const param = this.ignorePagination;
      this.ignoreListLoading = true;
      try {
        const res = await this.$api.chiguaData.getUserIgnore(param);
        // 删除后如果本页为空，则跳到前一页
        if (res.data.length === 0 && this.ignorePagination.currPage > 1) {
          this.ignorePagination.currPage = this.ignorePagination.currPage - 1;
          this.getUserIgnore();
          return;
        }
        this.ignoreList = res.data;
        const { curPage, count } = res;
        this.ignorePagination.currPage = curPage;
        this.ignorePaginationCount = count;
      } finally {
        this.ignoreListLoading = false;
      }
    },
    async addUserIgnore(userId) {
      await this.$api.chiguaData.addUserIgnore({ userId });
      this.getUserIgnore();
    },
    delUserIgnore(id) {
      this.$confirm("该操作会删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        await this.$api.chiguaData.delUserIgnore(id);
        this.getUserIgnore();
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    async searchUserList(page) {
      if (!this.inputValue) {
        this.$message.warning("搜索名称不能为空");
        return;
      }
      const param = {
        currPage: page || this.userPagination.curPage,
        limit: this.userPagination.size,
        name: this.inputValue,
      };
      this.userListLoading = true;
      try {
        const res = await this.$api.chiguaData.searchUserList(param);
        this.userList = res.data;
        const { curPage, count, size } = res;
        this.userPagination = {
          curPage,
          count,
          size,
        };
      } finally {
        this.userListLoading = false;
      }
    },
  },
  destroyed() {},
};
</script>
    
<style lang='scss' scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 0;
  }
}
.ignore-title {
  background: rgba(127, 118, 189, 1);
  color: #fff;
  padding: 5px 100px;
  border-radius: 4px;
}

.title {
  background: #eceafa;
}

.table-header {
  box-sizing: border-box;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}
.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
}
.del-btn {
  width: 48px;
  height: 20px;
  text-align: center;
  color: #f394ac;
  line-height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
}
.other {
  font-size: 14px;
  width: 54px;
  height: 20px;
  text-align: center;
  color: #fff;
  line-height: 20px;
  border-radius: 4px;
}
.left-wrap {
  width: 40%;
  padding-right: 10px;
  border-right: 1px solid #c5c0e0;
  .title {
    display: inline-block;
    padding: 3px 16px;
    margin-bottom: 8px;
    border-radius: 3px;
  }
}
.left-list {
  height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin: 10px 0;
  .add-btn {
    background-image: url("../../../../assets/add_btn_n.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 18px;
    height: 24px;
  }
}
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.title1 {
  width: 100%;
  height: 24px;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  color: #333333;
  line-height: 24px;
  text-align: center;
  background: #eceafa;
  border-radius: 4px;
  margin-bottom: 8px;
}
</style>