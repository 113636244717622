<!--  -->
<template>
  <div>
    <div class="filter-warp">
      <div :class="{'m-b-10': showStatus}">
        <el-select
          placeholder="请选择剧本"
          clearable
          v-model="scenarioId"
          size="mini"
          class="w-120 m-r-10"
          v-if="showScenario"
          @change="filterChange"
        >
          <el-option value="" label="全部剧本"></el-option>
          <el-option
            v-for="item in scenarioList"
            :key="item.id"
            :value="item.id"
            :label="item.scenarioName"
          ></el-option>
        </el-select>
        <el-select
          clearable
          placeholder="请选择状态"
          v-model="status"
          size="mini"
          class="m-r-10"
          style="width: 120px"
          @change="filterChange"
          v-if="showStatus"
        >
          <el-option
            v-for="item in detailStatus"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
            <span style="float: left">{{ item.label }}</span>
            <question-tooltip :text="item.text" v-if="item.text" />
          </el-option>
        </el-select>
        <el-select
          size="mini"
          class="w-120 m-r-10"
          v-model="platform"
          placeholder="请选择平台"
          @change="filterChange"
        >
          <el-option label="全平台" :value="''"> </el-option>
          <el-option
            v-for="item in platformOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="showStatus" style="width: 500px"></div>
      <div class="bg-white time-label">统计时间：</div>
      <el-date-picker
        style="border: none; width: 300px"
        size="mini"
        v-model="filterTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        @change="filterChange"
        value-format="timestamp"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        :unlink-panels="true"
      >
      </el-date-picker>
      <div class="bg-white pr-1 time-label">； 共{{ diffTime }}天</div>
      <el-checkbox
        class="m-l-20"
        size="mini"
        v-model="ignoreFlag"
        @change="filterChange"
        label="剔除名单"
      ></el-checkbox>
      <el-button
        @click="openDialog"
        class="m-l-20 w-100"
        size="mini"
        type="primary"
        >编辑</el-button
      >
    </div>
    <ignore-dialog
      v-if="showDialog"
      :dialogVisible.sync="dialogVisible"
      @change="dialogChange"
      ref="ignoreDialog"
    />
  </div>
</template>

<script>
import ignoreDialog from "./ignoreDialog";
import { timestampToDate } from "@tools";
import { mapState } from "vuex";
import QuestionTooltip from "../../../../components/common/questionTooltip.vue";
export default {
  components: {
    ignoreDialog,
    QuestionTooltip,
  },
  props: {
    showScenario: {
      default: false,
    },
    showStatus: {
      default: false,
    },
  },
  data() {
    this.pickerOptions = {
      shortcuts: [
        {
          text: "最近7天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近10天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一年",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        return (
          time.getTime() < new Date("2020-08-10").getTime() ||
          time.getTime() > new Date().getTime()
        );
      },
    };
    return {
      showDialog: false,
      scenarioId: "",
      dialogVisible: false,
      platform: "",
      platformOptions: [
        {
          label: "微信",
          value: "customer",
        },
      ],
      ignoreFlag: true,
      filterTime: [
        new Date().getTime() - 3600 * 1000 * 24 * 30,
        new Date().getTime(),
      ],
      status: "",
      detailStatus: [
        { label: "全部状态", value: "" },
        { label: "未结束", value: 0 },
        { label: "中途退出", value: 2 },
        {
          label: "中途退出①",
          value: 11,
          text: "剧本有剧本内容收费项，用户在未到达剧本内容商品的付费时机前退出。",
        },
        {
          label: "中途退出②",
          value: 12,
          text: "剧本有剧本内容收费项，用户在到达剧本内容商品的付费时机后，到达“剧终投票”前，且没有购买剧本内容，退出。",
        },
        {
          label: "中途退出④",
          value: 14,
          text: "剧本有剧本内容收费项，用户在到达剧本内容商品的付费时机后，到达“剧终投票”前，且购买了剧本内容后，退出。",
        },
        { label: "正常退出", value: 1 },
        {
          label: "正常退出③",
          value: 13,
          text: "剧本有剧本内容收费项，用户到达“剧终投票”后，且没有购买剧本内容，退出。",
        },
        {
          label: "正常退出⑤",
          value: 15,
          text: "剧本有剧本内容收费项，用户到达“剧终投票”后，且购买了剧本内容后，退出。",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    diffTime() {
      const [start, end] = this.filterTime;
      const diff = end - start + 1000 * 60 * 60 * 24; // 加一天
      const date = (Math.floor(diff) / (1000 * 60 * 60 * 24)).toFixed(0);
      return date;
    },
    ...mapState(["scenarioList"]),
  },
  mounted() {},
  methods: {
    openDialog() {
      this.showDialog = false;
      this.$nextTick(() => {
        this.showDialog = true;
        setTimeout(() => {
          this.$refs["ignoreDialog"].openDialog();
        }, 0);
      });
    },
    dialogChange() {
      this.showDialog = false;
      this.filterChange();
    },
    filterChange() {
      const { ignoreFlag, filterTime, platform, diffTime, scenarioId, status } =
        this;
      let startTime = timestampToDate(filterTime[0], false),
        endTime = timestampToDate(filterTime[1], false);
      const params = {
        ignoreFlag: !!ignoreFlag,
        startTime,
        endTime,
        platform,
        scenarioId,
        status,
      };
      if (!this.showScenario) {
        delete params.scenarioId;
      }
      if (!this.showStatus) {
        delete params.status;
      }
      this.$emit("dealFilter", params, diffTime);
    },
  },
  destroyed() {},
};
</script>
    
<style lang='scss' scoped>
.filter-warp {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
  width: 100%;
  .w-120 {
    width: 120px;
  }
  .w-100 {
    width: 100px;
  }
  .time-label {
    height: 100%;
    line-height: 28px;
  }
}
</style>